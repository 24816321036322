import Swal from "sweetalert2";
export const isStoreOpen = () => {
    // Get the current date and time in the Eastern Time Zone
    const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Denver", // Set the time zone to Eastern Time Zone
    });
  
    // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
    const currentDayOfWeek = new Date(currentDate).getDay();
  
    
    // Define the store's opening hours
    const openingHours = [
      { day: 0, start: "12:00", end: "20:45" }, // Sunday
      { day: 1, start: "11:00", end: "20:45" }, // Monday
      { day: 2, start: "11:00", end: "20:45" }, // Tuesday
      { day: 3, start: "11:00", end: "20:45" }, // Wednesday
        { day: 4, start: "11:00", end: "20:45" }, // Thursday
      { day: 5, start: "11:00", end: "20:45" }, // Friday
      { day: 6, start: "12:00", end: "20:45" }, // Saturday
    ];

    // Check if the current time is within any of the store's opening hours
    const isOpen = openingHours.some((hour) => hour.day === currentDayOfWeek);
  
    return isOpen;
};

  

export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours: Mon-Fri 11 AM - 9:00PM / Sat-Sun 12PM - 9:00 Pm',

    })
  }
